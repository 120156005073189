import useResizeObserver from '@hooks/useResizeObserver'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
import { IPlacement } from '@typesApp/cmsPlacement/Placement'
import { isLXTeaser } from '@typesApp/teaser'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import { useCallback, useMemo, useState } from 'react'
import { CmsCta } from '../CmsCta'
import { useStyledAchorButtons } from './hooks'
import styles from './styles/index.module.scss'

type TAchorButtons = {
  placement: IPlacement | ICMCollection
}

const AnchorButtons: React.FC<TAchorButtons> = ({ placement }) => {
  const router = useRouter()
  const [hasScrollbar, setHasScrollbar] = useState(false)
  const currentLocalizedPath = `/${router.locale?.toLowerCase()}${router.asPath}`
  const items = useMemo(
    () => ('items' in placement ? placement?.items?.filter(isLXTeaser) : placement?.teasableItems?.filter(isLXTeaser)),
    [placement]
  )

  const onResize = useCallback((entries: ResizeObserverEntry[]) => {
    for (const entry of entries) {
      setHasScrollbar(entry.target.scrollWidth > entry.target.clientWidth)
    }
  }, [])

  const { setRef } = useResizeObserver(onResize)
  const { styledCtas } = useStyledAchorButtons(items, currentLocalizedPath)

  if (!items || !items.length) return null

  return (
    <section
      ref={setRef}
      className={clsx(styles['anchor-buttons'], {
        'has-scrollbar': hasScrollbar,
      })}
    >
      {styledCtas.map((target, index) => {
        return (
          <CmsCta
            elementId={`HomePage_Placement1_Tile${index + 1}`}
            key={target.callToActionText}
            teaserCtaSetting={target}
            teaserIcon={items[index].teaserIcon}
            currentPath={currentLocalizedPath}
            overrideStyle={target.style}
            data-hash-action={target.callToActionHash}
          />
        )
      })}
    </section>
  )
}

export default AnchorButtons
