import { StyledLoader } from '@components/UI/Loader'
import clsx from 'clsx'
import React, { MouseEventHandler } from 'react'
import styles from './styles/Button.module.scss'

export type Button = {
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
  variant?: 'contained' | 'outlined'
  color?: 'primary' | 'secondary' | 'tertiary'
  size?: 'medium' | 'big'
  isLoading?: boolean
  loaderSize?: number
  disabled?: boolean
  style?: React.CSSProperties
  className?: string
  children: React.ReactNode
}

export const Button: React.FC<Button> = ({
  onClick,
  className,
  children,
  style,
  variant = 'contained',
  color = 'primary',
  disabled = false,
  isLoading = false,
  loaderSize = 20,
  size = 'medium',
}) => {
  return (
    <button
      className={clsx(styles['button'], className, variant, color, {
        isLoading,
        big: size === 'big',
      })}
      onClick={onClick}
      style={style}
      disabled={disabled}
    >
      {isLoading ? <StyledLoader size={loaderSize} /> : children}
    </button>
  )
}
