import { ILXTeaser, ITeaserCallToAction } from '@typesApp/cmsPlacement/LXTeaser'
import { areEquals } from '@utils/common'
import { useEffect, useState } from 'react'

export const useStyledAchorButtons = (items: ILXTeaser[], currentLocalizedPath: string) => {
  const [styledCtas, setStyledCtas] = useState(items.map(item => ({ ...item.teaserLXCallToActionSettings[0] })))

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setStyledCtas(prev => {
          return prev.map(prevCta => {
            return {
              ...prevCta,
              style:
                entry.isIntersecting && entry.target.id === prevCta.callToActionHash
                  ? 'cta-fill-primary'
                  : ('cta-fill-secondary' as ITeaserCallToAction['style']),
            }
          })
        })
      },
      {
        threshold: 0.4,
        rootMargin: '-20% 0px -30% 0px',
      }
    )

    items.forEach(item => {
      const cta = item.teaserLXCallToActionSettings[0]
      const target = document.getElementById(cta.callToActionHash ?? '')
      const pointToCurrentPage = areEquals(currentLocalizedPath, cta.target?.formattedUrl)
      if (target && pointToCurrentPage) {
        observer.observe(target)
      }
    })
  }, [currentLocalizedPath, items])

  return { styledCtas }
}
