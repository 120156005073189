import { Button } from '@components/UI/Button'
import { styled } from '@mui/material'

export const StyledCmsAnchorButton = styled(Button, {
  name: 'AnchorButton',
})(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(4),
  backgroundColor: theme.palette.secondary.main,
  width: '100%',
}))

export const StyledCmsIcon = styled('div', {
  name: 'AnchorButtonIcon',
})(() => ({
  height: 32,
  width: 32,

  '& svg': {
    height: 'inherit',
    width: 'inherit',
  },
}))
