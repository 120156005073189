import { useCallback, useMemo, useRef } from 'react'

type ResizeCallback = (entry: ResizeObserverEntry[]) => void

const useResizeObserver = (callback: ResizeCallback) => {
  const refInternal = useRef<HTMLElement | null>(null)

  const handleResize: ResizeCallback = useCallback(
    (entries: ResizeObserverEntry[]) => {
      if (entries && entries.length > 0) {
        callback(entries)
      }
    },
    [callback]
  )

  const resizeObserver = useMemo(() => {
    // Check if ResizeObserver is defined
    if (typeof ResizeObserver !== 'undefined') {
      return new ResizeObserver(handleResize)
    } else {
      // Return a no-op function if ResizeObserver is not defined
      return { observe: () => {}, disconnect: () => {} }
    }
  }, [handleResize])

  const setRef = useCallback(
    (node: HTMLElement | null) => {
      if (refInternal.current) {
        // Make sure to cleanup any events/references added to the last instance
        resizeObserver.disconnect()
      }
      // Check if a node is actually passed. Otherwise node would be null.
      // You can now do what you need to, addEventListeners, measure, etc.
      if (!node) return
      resizeObserver.observe(node)
      refInternal.current = node
    },
    [resizeObserver]
  )

  return { setRef }
}

export default useResizeObserver
